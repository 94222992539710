import { IStoreEnvironmentTemplate } from '../index';

export const production: IStoreEnvironmentTemplate = {
  REACT_APP_APP_URL: 'https://app.busybusy.io/',
  REACT_APP_GRAPHQL_URL: 'https://graphql.busybusy.io/',
  REACT_APP_LEGACY_URL: 'https://busybusy.io/',
  REACT_APP_RESTAPI_URL: 'https://api.busybusy.io/',
  REACT_APP_PLATFORM_REPORT_URL: `https://reports-platform-api.busybusy.io/`,
  REACT_APP_CUSTOM_REPORT_URL: `https://reports-api.busybusy.io/`,
  REACT_APP_DISCOVERY_URL: 'https://discovery.busybusy.io/',
  REACT_APP_EQUIPMENT_URL: 'https://equipment.busybusy.io/',
  REACT_APP_GUSTO_URL: 'https://gusto.busybusy.io/',
  REACT_APP_LEGACY_QBRPC_URL: `https://qb.busybusy.io`,
  REACT_APP_COMPANY_CAM_URL: 'https://company-cam-api.busybusy.io',
  REACT_APP_COMPANY_CAM_SOCKET_URL: 'wss://company-cam-api.busybusy.io',
  REACT_APP_QBD_API_URL: `https://qbd-api.busybusy.io`,
  REACT_SLACK_URL: `https://hooks.slack.com/services/`,
  REACT_APP_AGAVE_URL: `https://agave.busybusy.io/`,
  REACT_APP_ADP_API_URL: `https://adp.busybusy.io`,
  REACT_APP_MACROS_URL: `https://macros.busybusy.io`,
};

export default production;

/*
  Launch darkly flags must be declared here to allow the middleware to manage them
*/

import { IFeatureFlags, TFlagKey } from 'types';

export const launchDarklyFeatures: IFeatureFlags = {
  REACT_MAIN_APP: true,
  // pro features:  default to disabled
  KIOSK_REPORT: true,
  PHOTOS_REACT: false,
  PRO: false,
  GUSTO_PAYROLL_PAID: 'unpaid',

  TIME_OFF_REQUEST: true,
  WORK_ACCOMPLISHED: false,
  PHOTO_VERIFICATION: false,
  DAILY_REPORTS: false,
  DOCUMENTS: false,
  COMPANY_CAM_INTEGRATION: false,
  LEGACY_REPORTS_ACCESSIBLE: true,
  REACT_SUBSCRIPTION_SETTINGS: false,
  DASHBOARD_WEB_REFRESH: true,
  ZAPIER_EMBED: true,
  PREMIUM: false,
  PREMIUM_TRIAL: false,
  AGAVE_INTEGRATIONS: false,
  ATTENDANCE_REPORT: false,
  LOCATION_FLAGGING: false,
  WEEKLY_TIME_GRID: false,
  PER_DIEM: false,
  CUSTOM_DAILY_SIGN_OFF: false,
  SAFETY_INCIDENTS: false,
  SAFETY_INSPECTIONS: false,
  SAFETY_OBSERVATIONS: false,
  SAFETY_TRAINING: false,
  LEGACY_PER_DIEM_GUSTO_ADJUSTMENT: false,
  JOB_SAFETY_ANALYSIS: false,
  MACRO_INTERNAL: false,

  // under development: default to disabled
  TIME_ENTRY_HISTORY_LOG_FEED: false,
  ADD_MULTI_DAY_ENTRIES: false,
  CLOCK_IN_RESTRICTION: false,
  DAILY_SIGN_OFF_RESOLUTION: true,
  JIT_QUERY: false,
  DAILY_SIGN_IN_QUESTIONS: false,
  REACT_ACTIVITY_REPORTS: false,
  ZENDESK: false,
  GUSTO_PAYROLL: false,
  GUSTO_PAID_CUSTOMER: false,
  REACT_PAYROLL: false,
  SERVER_OUTAGE: false,
  INTEGRATION_API_TOKEN_MANAGEMENT: false,
  REACT_SETTINGS_INTEGRATION: false,
  REACT_SETTINGS_INTEGRATION_QUICKBOOKS: false,
  ONBOARDING_FEATURE_LISTS: false,
  BULLETIN_FEATURE: false,
  PERFORMANCE_SCORES: false,
  GPS_STATUS_DISABLED: false,
};

export const launchDarklyFeatureKeys: TFlagKey[] = Object.keys(launchDarklyFeatures) as TFlagKey[];

Object.freeze(launchDarklyFeatures);
Object.freeze(launchDarklyFeatureKeys);
